import { FC } from "react";
import { Image, List, Space, Typography } from "antd";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { decodeSpecialChar } from "../../utils/decode";
import { toUpperCase } from "../../utils/toUpperCase";

import "./index.scss";

const config = {
  "fast-preview": {
    disabled: false,
  },
  tex2jax: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
  },
  svg: {
    fontCache: "global",
  },
};

const Question: FC<{ data: Question; showAnswer?: boolean; showBlank?: boolean }> = ({
  data,
  showBlank = false,
  showAnswer = false,
}) => {
  return (
    <div className="question">
      <Space direction="vertical" style={{ display: "flex" }}>
        <MathJaxContext version={3} config={config}>
          <List
            split={false}
            dataSource={data.blocks}
            renderItem={(block) => (
              <List.Item style={{ padding: "0px", breakInside: "avoid" }}>
                {block.type === "image" ? (
                  <Image preview={false} src={block.data.file.url} />
                ) : block.type === "paragraph" ? (
                  <MathJax dynamic>{decodeSpecialChar(block.data.content)}</MathJax>
                ) : block.type === "imageAnswer" && showAnswer ? (
                  <div style={{ flexDirection: "column" }}>
                    <Typography.Title level={5}>Answer:</Typography.Title>
                    <Image preview={false} src={block.data.file.url} width="100%" style={{ objectFit: "contain" }} />
                  </div>
                ) : block.type === "textAnswer" && showAnswer ? (
                  <div style={{ flexDirection: "column" }}>
                    <Typography.Title level={5}>Answer:</Typography.Title>
                    <MathJax dynamic>{decodeSpecialChar(block.data.content)}</MathJax>
                  </div>
                ) : block.type === "blankLine" && showBlank ? (
                  <List
                    style={{ width: "100%" }}
                    dataSource={[...Array(block.data.number).keys()]}
                    renderItem={(i) => (
                      <hr
                        style={{
                          marginTop: "2em",
                          height: "0px",
                          border: "none",
                          borderTop: "2px solid black",
                        }}
                        key={i}
                      />
                    )}
                  />
                ) : (
                  <></>
                )}
              </List.Item>
            )}
          />
        </MathJaxContext>
        <Space className="tags" style={{}}>
          <Space>
            <Typography.Text strong>Exam:</Typography.Text>
            <Typography.Text>{data.exam ? toUpperCase(data.exam) : "--"}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Subject:</Typography.Text>
            <Typography.Text>{data.exam ? toUpperCase(data.subject) : "--"}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Year:</Typography.Text>
            <Typography.Text>{data.properties?.year ? data.properties.year : "--"}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Mark:</Typography.Text>
            <Typography.Text>{data.properties?.mark ? data.properties.mark : "--"}</Typography.Text>
          </Space>
        </Space>
      </Space>
    </div>
  );
};

export { Question };
