import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "../../components/editor";
import { db } from "../../utils/utils";
import { Page } from "../../components/template";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import {
  Button,
  InputNumber,
  Layout,
  message,
  Select,
  Skeleton,
  Space,
  TreeSelect,
  Typography,
} from "antd";

import { DeleteButton } from "../../components/delete";
import { QUESTION_DB, TAG_DB, TAG_ID } from "../../utils/env";
import { sort } from "../../utils/sort";

import type { DefaultOptionType } from "rc-tree-select/lib/TreeSelect";

const { Content, Sider } = Layout;

const collection = db.collection(QUESTION_DB);

const config = {
  "fast-preview": {
    disabled: false,
  },
  tex2jax: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
  },
};

const loadData = (questionId: string) => {
  return collection
    .where({ _id: questionId })
    .get()
    .then((res) => {
      return res.data[0];
    });
};

const QuestionEditor: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { questionId } = params;
  const [isNew, setIsNew] = useState<Boolean>(false);
  const [data, setData] = useState<EditorData>({
    _id: questionId!,
    _openid: "",
    version: "",
    blocks: [],
  });
  const menu = [
    {
      title: "Loading...",
      value: "",
    },
  ];
  const [subjectTree, setSubjectTree] = useState<DefaultOptionType[]>(menu);
  const [treeValue, setTreeValue] = useState<string>("");
  const [exam, setExam] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [exams, setExams] = useState<Exams>({});
  const [tags, setTags] = useState<Properties>({});

  useEffect(() => {
    if (questionId && questionId === "new") {
      setIsNew(true);
      db.collection(TAG_DB)
        .where({ _id: TAG_ID })
        .get()
        .then((res) => {
          const { _id, _openid, ...exams } = res.data[0];
          setExams(exams);
          setSubjectTree(exams2tree(exams));
        })
        .catch((err) => console.error(err));
    } else if (questionId) {
      const fetchData = async () => {
        const { properties, exam, subject, ...initData } = await loadData(
          questionId
        );
        db.collection(TAG_DB)
          .where({ _id: TAG_ID })
          .get()
          .then((res) => {
            const { _id, _openid, ...exams } = res.data[0];
            setSubjectTree(exams2tree(exams));
            setExams(exams);
            setData(initData);
            if (properties) {
              setTags(properties);
            }
            if (exam) {
              setExam(exam);
            }
            if (subject) {
              setSubject(subject);
            }
            if (exam && subject) {
              setTreeValue(`${exam}#${subject}`);
            }
          })
          .catch((err) => console.error(err));
      };
      fetchData().catch((err) => console.error(err));
    }
  }, []);

  const exams2tree = (exams: Exams) => {
    return Object.entries(exams as Exams).map((entry) => {
      const [, exam] = entry;
      return {
        title: exam.name,
        value: exam.name,
        children: Object.entries(exam.subjects).map((entry) => {
          const [, subject] = entry;
          return {
            title: subject.name,
            value: `${exam.name}#${subject.name}`,
          };
        }),
      };
    });
  };

  const saveData = () => {
    const { _id, _openid, time, version, ...content } = data;
    // console.log(tags);
    if (!exam) {
      message.error(
        "Cannot save question, please assign an Exam Board for this question."
      );
      return;
    }
    if (!subject) {
      message.error(
        "Cannot save question, please assign a Subject for this question."
      );
      return;
    }
    if (Object.keys(tags).length === 0) {
      message.error(
        "Cannot save question, please assign at least one tag for this question."
      );
      return;
    }
    if (content.blocks.length === 0) {
      message.error("Cannot save question, please add some content.");
      return;
    }
    const question = {
      ...content,
      properties: tags,
      exam: exam,
      subject: subject,
    };
    // console.log(question);
    if (isNew) {
      collection
        .add(question)
        .then((res) => {
          console.log(res);
          message.success("New question saved to database");
          navigate("/questions");
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      collection
        .doc(_id)
        .set(question)
        .then((res) => {
          console.log(res);
          message.success("Update has been saved to database");
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const deleteData = () => {
    console.log(`deleting ${data!._id}`);
    collection
      .doc(data?._id)
      .remove()
      .then((res) => {
        console.log(`${res.deleted} docs deleted`);
        navigate("/questions");
      })
      .catch((e) => {});
  };

  const handleTreeSelectChange = (key: string) => setTreeValue(key);

  useEffect(() => {
    const [exam, subject] = treeValue.split("#");
    setExam(exam);
    setSubject(subject);
    if (isNew && exams && exam && subject && exams[exam]?.subjects[subject]) {
      setTags(
        Object.fromEntries(
          Object.values(exams[exam].subjects[subject].tags).map((t) => {
            return [t.name, t.default];
          })
        )
      );
    }
  }, [treeValue, exams]);

  return (
    <Page name={"Questions"}>
      <Layout hasSider className="site-layout-background">
        <Content
          className="site-page-background"
          style={{
            padding: "24px 0px",
            margin: 24,
            minHeight: 280,
            overflowY: "scroll",
          }}
        >
          <MathJaxContext version={3} config={config}>
            <MathJax dynamic hideUntilTypeset={"first"}>
              {isNew || (data.blocks && data.blocks.length > 0) ? (
                <Editor data={data} setData={setData} />
              ) : (
                <Skeleton active />
              )}
            </MathJax>
          </MathJaxContext>
        </Content>
        <Sider className="site-layout-background" width={350}>
          <Space
            direction="vertical"
            className="site-page-background"
            style={{
              padding: 10,
              margin: "24px 24px 24px 0px",
              width: "calc(100% - 24px)",
              minHeight: 280,
            }}
          >
            <TreeSelect
              style={{ width: "100%" }}
              value={treeValue}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={subjectTree}
              placeholder="Please select"
              treeDefaultExpandAll
              onChange={handleTreeSelectChange}
            />
            {exam &&
            exam !== "" &&
            subject &&
            subject !== "" &&
            exams[exam] &&
            exams[exam].subjects[subject] ? (
              Object.entries(exams[exam].subjects[subject].tags).map(
                ([, tag]) => {
                  switch (tag.type) {
                    case "integer": {
                      return (
                        <Space
                          direction="vertical"
                          style={{ width: "100%" }}
                          key={tag.name}
                        >
                          <Typography.Text>{tag.name}</Typography.Text>
                          <InputNumber
                            size="small"
                            style={{ width: "100%" }}
                            max={tag.max}
                            min={tag.min}
                            value={
                              tags[tag.name] ? tags[tag.name] : tag.default
                            }
                            onChange={(e) =>
                              setTags({ ...tags, [tag.name]: e })
                            }
                          />
                        </Space>
                      );
                    }
                    case "string": {
                      return (
                        <Space
                          direction="vertical"
                          style={{ width: "100%" }}
                          key={tag.name}
                        >
                          <Typography.Text>
                            {tag.name.charAt(0).toUpperCase() +
                              tag.name.slice(1)}
                          </Typography.Text>
                          <Select
                            mode="tags"
                            size="small"
                            style={{ width: "100%" }}
                            value={[
                              tags[tag.name] ? tags[tag.name] : tag.default,
                            ]}
                            onChange={(key) => {
                              const newOption = key[1];
                              if (tag.options.includes(newOption as string)) {
                                setTags({ ...tags, [tag.name]: newOption });
                              }
                            }}
                          >
                            {tag.options.sort(sort).map((option) => (
                              <Select.Option key={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Select>
                        </Space>
                      );
                    }
                    case "multi-level": {
                      return (
                        <Space
                          direction="vertical"
                          style={{ width: "100%" }}
                          key={tag.name}
                        >
                          <Typography.Text>
                            {tag.name.charAt(0).toUpperCase() +
                              tag.name.slice(1)}
                          </Typography.Text>

                          <TreeSelect
                            allowClear
                            placeholder="please select an option"
                            style={{ width: "100%" }}
                            value={
                              tags[tag.name] ? tags[tag.name] : tag.default
                            }
                            treeData={Object.values(tag.options)
                              ?.sort((a, b) => sort(a.name, b.name))
                              .map(({ name, options }) => {
                                return {
                                  title: name,
                                  value: name,
                                  children: options
                                    ?.sort(sort)
                                    .map((option) => {
                                      return {
                                        title: option,
                                        value: `${name}@${option}`,
                                      };
                                    }),
                                };
                              })}
                            onChange={(key) => {
                              setTags({ ...tags, [tag.name]: key });
                            }}
                          />
                        </Space>
                      );
                    }
                  }
                }
              )
            ) : (
              <></>
            )}
          </Space>
          <Space
            direction="vertical"
            style={{ width: "100%", paddingRight: 24 }}
          >
            <Button type="primary" onClick={() => saveData()} block>
              Save
            </Button>
            <DeleteButton handleDelete={() => deleteData()} />
          </Space>
        </Sider>
      </Layout>
    </Page>
  );
};

export { QuestionEditor };
