const re = /^\d+/;

const sort = (a: string, b: string) => {
  const regexA = a.match(re);
  const regexB = b?.match(re);
  // console.log(a, regexA, b, regexB);
  if (!!regexA && regexA[0] && !!regexB && regexB[0]) {
    // console.log(a, Number(regexA[0]), b, Number(regexB[0]));
    return Number(regexA[0]) - Number(regexB[0]);
  } else {
    return 1;
  }
};

export { sort };
