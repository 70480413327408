import { FC, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Input,
  Layout,
  Menu,
  PageHeader,
  Space,
  Typography,
} from "antd";

import { Page } from "../../components/template";
import { Delete } from "../../components/delete";
import { TagInt, TagMul, TagNew, TagStr } from "./tags";
import { db } from "../../utils/utils";
import { TAG_DB, TAG_ID } from "../../utils/env";

import examBoards from "./exams.json";

const { Content, Sider } = Layout;

const collection = db.collection(TAG_DB);

const defaultTags: { [key: string]: Tag } = {
  mark: {
    name: "mark",
    type: "integer",
    min: 1,
    max: 100,
    default: 1,
  },
  year: {
    name: "year",
    type: "integer",
    min: 2020,
    max: 2022,
    default: 2020,
  },
};

const TagEditor: FC = () => {
  const [subjectName, setSubjectName] = useState<string>("");
  const [examName, setExamName] = useState<string>("");

  const [exams, setExams] = useState<Exams>(examBoards as Exams);

  const updateExams = (newExams: Exams) => {
    // console.log(newExams);
    collection
      .doc(TAG_ID)
      .set(newExams)
      .then((res) => {
        collection
          .where({ _id: TAG_ID })
          .get()
          .then((res) => {
            const { _id, _openid, ...data } = res.data[0];
            // @ts-ignore:next-line
            setExams(data as Exams);
          });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const [newSubjectName, setNewSubjectName] = useState<string>("");
  const [newExamName, setNewExamName] = useState<string>("");
  const handleAddSubject = () => {
    const value = newSubjectName.trim();
    if (value in exams[examName].subjects) return;
    if (value && value !== "") {
      let newExams: Exams = { ...exams };
      let newExam: Exam = newExams[examName];
      newExam.subjects[value] = {
        name: value,
        tags: defaultTags,
      };
      newExams[examName] = newExam;

      updateExams(newExams);
    }
  };
  const handleAddExamBoard = () => {
    const value = newExamName.trim();
    if (value in exams) return;
    if (value && value !== "") {
      updateExams({
        ...exams,
        [value]: { name: value, subjects: {} },
      });
    }
  };

  const handleDeleteSubject = (examName: string, subjectName: string) => {
    let newExams: Exams = { ...exams };
    let newExam: Exam = newExams[examName];
    delete newExam.subjects[subjectName];
    updateExams(newExams);
  };

  const handleDeleteExam = (examName: string) => {
    let newExams = { ...exams };
    delete newExams[examName];
    updateExams(newExams);
  };

  useEffect(() => {
    collection
      .where({ _id: TAG_ID })
      .get()
      .then((res) => {
        const { _id, _openid, ...data } = res.data[0];
        // @ts-ignore:next-line
        setExams(data as Exams);
      });
  }, []);

  const items: MenuItem[] = [
    ...Object.entries(exams).map((entry) => {
      const [examName, exam] = entry;
      return {
        label: (
          <Space style={{ justifyContent: "space-between", width: "95%" }}>
            <Typography.Text>{exam.name}</Typography.Text>
            <Delete handleDelete={() => handleDeleteExam(examName)} />
          </Space>
        ),
        key: exam.name,
        children: [
          ...Object.entries(exam.subjects).map(([, subject]) => {
            return {
              label: (
                <Space
                  style={{ justifyContent: "space-between", width: "95%" }}
                >
                  <Typography.Text>{subject.name}</Typography.Text>
                  <Delete
                    handleDelete={() =>
                      handleDeleteSubject(examName, subjectName)
                    }
                  />
                </Space>
              ),
              key: examName + "_" + subject.name,
            };
          }),
          {
            label: (
              <Space>
                <Input
                  size="small"
                  placeholder="Add Subject"
                  value={newSubjectName}
                  onChange={(e) => setNewSubjectName(e.target.value)}
                />
                <Button size="small" onClick={handleAddSubject}>
                  Add
                </Button>
              </Space>
            ),
            key: examName + "_addSubject",
          },
        ],
      };
    }),
    {
      label: (
        <Space>
          <Input
            size="small"
            placeholder="Add Exam Board"
            value={newExamName}
            onChange={(e) => setNewExamName(e.target.value)}
          />
          <Button size="small" onClick={handleAddExamBoard}>
            Add
          </Button>
        </Space>
      ),
      key: "addExamBoard",
    },
  ];

  const handleSelect = (keyPath: Array<string>) => {
    if (keyPath.length > 1) {
      setExamName(keyPath[1]);
    }

    const [, ...key] = keyPath[0].split("_");

    setSubjectName(key.join("_"));
  };

  const siderWidth = 450;

  useEffect(() => {
    localStorage.removeItem("query");
    localStorage.removeItem("page");
  }, []);

  return (
    <Page name={"Tags"}>
      <Layout className="site-layout-background">
        <Content
          className="site-page-background"
          style={{
            // padding: "24px 0px",
            margin: 24,
            minHeight: 280,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Sider
            className="site-page-background"
            width={siderWidth}
            style={{ overflowY: "scroll" }}
          >
            <PageHeader title="Tag Editor" />
            <Menu
              style={{ width: siderWidth }}
              defaultSelectedKeys={["Alevel"]}
              defaultOpenKeys={["sub1"]}
              mode={"inline"}
              theme={"light"}
              items={items}
              onSelect={(e) => handleSelect(e.keyPath)}
            />
          </Sider>
          <Content
            className="site-page-background"
            style={{
              padding: 20,
              overflowY: "scroll",
            }}
          >
            <TagNew
              examName={examName}
              subjectName={subjectName}
              exams={exams}
              updateExams={updateExams}
            />
            <Divider />
            {Object.entries(
              exams[examName!] && exams[examName]["subjects"][subjectName!]
                ? exams[examName]["subjects"][subjectName].tags
                : {}
            ).map(([tagName, tag]) => {
              switch (tag.type) {
                case "integer": {
                  return (
                    <TagInt
                      key={`${examName}_${subjectName}_${tagName}`}
                      examName={examName}
                      subjectName={subjectName}
                      tagName={tagName}
                      exams={exams}
                      updateExams={updateExams}
                    />
                  );
                }
                case "string": {
                  return (
                    <TagStr
                      key={`${examName}_${subjectName}_${tagName}`}
                      examName={examName}
                      subjectName={subjectName}
                      tagName={tagName}
                      exams={exams}
                      updateExams={updateExams}
                    />
                  );
                }
                case "multi-level": {
                  return (
                    <TagMul
                      key={`${examName}_${subjectName}_${tagName}`}
                      examName={examName}
                      subjectName={subjectName}
                      tagName={tagName}
                      exams={exams}
                      updateExams={updateExams}
                    />
                  );
                }
              }
            })}
          </Content>
        </Content>
      </Layout>
    </Page>
  );
};

export { TagEditor };
