import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Space,
  TreeSelect,
  Typography,
} from "antd";

import { Delete } from "../../components/delete";

const getNewExams = (
  exams: Exams,
  examName: string,
  subjectName: string,
  tagName: string,
  newTag: Tag
): Exams => {
  let newExams: Exams = { ...exams };
  let newExam = newExams[examName];
  let newSubject = newExam.subjects[subjectName];

  newSubject.tags[tagName] = newTag;
  newExam.subjects[subjectName] = newSubject;
  newExams[examName] = newExam;

  return newExams;
};

const compareOptions = (a: string, b: string) => {
  const numa = a.match(/^\d+/);
  const numb = b.match(/\d+/);
  console.log(numa, numb);
  if (!!numa === true && !!numb === true) {
    return Number(numa![0]) - Number(numb![0]);
  } else if (!!numa === false && !!numb === true) {
    return -1;
  } else if (!!numa === true && !!numb === false) {
    return 1;
  } else {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }
};

const TagInt: FC<{
  examName: string;
  subjectName: string;
  tagName: string;
  exams: Exams;
  updateExams: (exams: Exams) => void;
}> = ({ examName, subjectName, tagName, exams, updateExams }) => {
  // console.log(examName, subjectName, tagName);
  const exam = exams[examName];
  const subject = exam.subjects[subjectName];
  const tag = subject.tags[tagName] as TagInt;
  const name = tagName;

  const handleMax = (v: number) => {
    const newTag = {
      ...tag,
      max: v,
      default: v < tag.default ? v : tag.default,
    } as TagInt;

    updateExams(getNewExams(exams, examName, subjectName, tagName, newTag));
  };

  const handleMin = (v: number) => {
    const newTag = {
      ...tag,
      min: v,
      default: v > tag.default ? v : tag.default,
    } as TagInt;

    updateExams(getNewExams(exams, examName, subjectName, tagName, newTag));
  };

  const handleDefault = (v: number) => {
    const newTag = { ...tag, default: v } as TagInt;
    updateExams(getNewExams(exams, examName, subjectName, tagName, newTag));
  };

  const handleDelete = () => {
    let newExams: Exams = { ...exams };
    delete newExams[examName].subjects[subjectName].tags[tagName];
    updateExams(newExams);
  };

  return (
    <Form
      size={"small"}
      layout="horizontal"
      labelCol={{ span: 3 }}
      labelAlign={"left"}
    >
      <Typography.Title level={4}>{name}</Typography.Title>
      <Form.Item label={`Default value`}>
        <InputNumber
          min={tag.min}
          max={tag.max}
          defaultValue={tag.default}
          onChange={handleDefault}
        />
      </Form.Item>
      <Form.Item label={`Max value`}>
        <InputNumber
          min={tag.min}
          defaultValue={tag.max}
          onChange={handleMax}
        />
      </Form.Item>
      <Form.Item label={`Min value`}>
        <InputNumber
          max={tag.max}
          defaultValue={tag.min}
          onChange={handleMin}
        />
      </Form.Item>
      <Delete handleDelete={handleDelete} />
      <Divider />
    </Form>
  );
};

const TagStr: FC<{
  examName: string;
  subjectName: string;
  tagName: string;
  exams: Exams;
  // setExams: Dispatch<SetStateAction<Exams>>;
  updateExams: (exams: Exams) => void;
}> = ({ examName, subjectName, tagName, exams, updateExams }) => {
  const exam = exams[examName];
  const subject = exam.subjects[subjectName];
  const tag = subject.tags[tagName] as TagStr;
  const name = tagName;
  const handleOptions = (v: Array<string>) => {
    const newTag = { ...tag, options: v.sort(compareOptions) } as TagStr;
    updateExams(getNewExams(exams, examName, subjectName, tagName, newTag));
  };
  const handleDefault = (v: Array<string>) => {
    let newV = v.length > 1 ? v[v.length - 1] : v[0];
    if (newV === undefined) {
      return;
    }
    newV = newV.includes("#") ? newV.split("#")[1] : newV;
    if (tag.options.includes(newV)) {
      const newTag = { ...tag, default: newV } as TagStr;
      updateExams(getNewExams(exams, examName, subjectName, tagName, newTag));
    }
  };
  const handleDelete = () => {
    let newExams: Exams = { ...exams };
    delete newExams[examName].subjects[subjectName].tags[tagName];
    updateExams(newExams);
  };
  return (
    <Form
      size={"small"}
      layout="horizontal"
      labelCol={{ span: 3 }}
      labelAlign={"left"}
    >
      <Typography.Title level={4}>{name}</Typography.Title>
      <Form.Item label={`Default value`}>
        <Select
          mode="tags"
          style={{ width: "100%" }}
          onChange={handleDefault}
          value={[tag.default]}
        >
          {tag.options.map((option) => (
            <Select.Option key={`${tag.name}#${option}`}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={`Options`}>
        <Select
          mode="tags"
          style={{ width: "100%" }}
          onChange={handleOptions}
          value={tag.options}
          maxTagCount={10}
        />
      </Form.Item>
      <Delete handleDelete={handleDelete} />
      <Divider />
    </Form>
  );
};

const TagMul: FC<{
  examName: string;
  subjectName: string;
  tagName: string;
  exams: Exams;
  // setExams: Dispatch<SetStateAction<Exams>>;
  updateExams: (exams: Exams) => void;
}> = ({ examName, subjectName, tagName, exams, updateExams }) => {
  const exam = exams[examName];
  const subject = exam.subjects[subjectName];
  const tag = subject.tags[tagName] as TagMul;
  const name = tagName;

  const handleL1Options = (options: Array<string>) => {
    const intersection = Object.values(tag.options).filter(({ name }) =>
      options.includes(name)
    );
    const intersection_name = intersection.map((item) => item.name);
    const _options = options.filter(
      (item) => !intersection_name.includes(item)
    );
    _options.forEach((option) => intersection.push({ name: option }));

    intersection.sort((a, b) => compareOptions(a.name, b.name));

    const newTag = {
      ...tag,
      options: Object.fromEntries(
        intersection.map((option) => [option.name, option])
      ),
    } as TagMul;
    updateExams(getNewExams(exams, examName, subjectName, tagName, newTag));
  };

  const handleL2Options = (name: string) => (options: Array<string>) => {
    const newTag = {
      ...tag,
      options: {
        ...tag.options,
        [name]: {
          name: name,
          options: options.sort(compareOptions),
        },
      },
    } as TagMul;
    updateExams(getNewExams(exams, examName, subjectName, tagName, newTag));
  };

  const handleDefault = (v: string) => {
    const newTag = { ...tag, default: v } as TagMul;
    updateExams(getNewExams(exams, examName, subjectName, tagName, newTag));
  };
  const handleDelete = () => {
    let newExams: Exams = { ...exams };
    delete newExams[examName].subjects[subjectName].tags[tagName];
    updateExams(newExams);
  };
  return (
    <Form
      size={"small"}
      layout="horizontal"
      labelCol={{ span: 3 }}
      labelAlign={"left"}
    >
      <Typography.Title level={4}>{name}</Typography.Title>
      <Form.Item label={`Default value`}>
        <TreeSelect
          allowClear
          multiple={false}
          style={{ width: "100%" }}
          treeData={Object.values(tag.options).map(({ name, options }) => {
            return {
              title: name,
              value: name,
              children: options?.map((option) => {
                return {
                  title: option,
                  value: `${name}@${option}`,
                };
              }),
            };
          })}
          onChange={handleDefault}
          value={tag.default}
        />
      </Form.Item>
      <Form.Item label={`Level 1 Options`}>
        <Select
          mode="tags"
          style={{ width: "100%" }}
          onChange={handleL1Options}
          value={Object.keys(tag.options)}
          maxTagCount={10}
        />
      </Form.Item>

      <Typography.Text
        strong
        style={{
          display: Object.keys(tag.options).length > 0 ? "block" : "none",
        }}
      >
        Level 2 Options
      </Typography.Text>

      {Object.entries(tag.options).map(([, { name, options }]) => (
        <Form.Item key={name} label={`${name}`}>
          <Select
            mode="tags"
            style={{ width: "100%" }}
            onChange={handleL2Options(name)}
            value={options ? options : []}
            maxTagCount={10}
          />
        </Form.Item>
      ))}
      <Delete handleDelete={handleDelete} />
      <Divider />
    </Form>
  );
};

const TagNew: FC<{
  examName: string;
  subjectName: string;
  exams: Exams;
  updateExams: (exams: Exams) => void;
}> = ({ examName, subjectName, exams, updateExams }) => {
  const [newTagType, setNewTagType] = useState<
    "string" | "integer" | "multi-level"
  >("integer");
  const [newTagName, setNewTagName] = useState<string>("");

  const newTagIntInit: TagInt = {
    name: "",
    type: "integer",
    max: 0,
    min: 0,
    default: 0,
  };
  const newTagStrInit: TagStr = {
    name: "",
    type: "string",
    default: "",
    options: [],
  };
  const newTagMulInit: TagMul = {
    name: "",
    type: "multi-level",
    default: "",
    options: {},
  };

  const [newTagInt, setNewTagInt] = useState<TagInt>(newTagIntInit);
  const [newTagStr, setNewTagStr] = useState<TagStr>(newTagStrInit);
  const [newTagMul, setNewTagMul] = useState<TagMul>(newTagMulInit);

  const getNewTags = () => {
    switch (newTagType) {
      case "string": {
        return { ...newTagStr, name: newTagName };
      }
      case "integer": {
        return { ...newTagInt, name: newTagName };
      }
      // multi-level
      default: {
        return { ...newTagMul, name: newTagName };
      }
    }
  };
  const newTag: Tag = getNewTags();

  const handleNewTagDefaultInt = (v: number) => {
    setNewTagInt({ ...newTagInt, default: v });
  };
  const handleNewTagMin = (v: number) => {
    setNewTagInt({
      ...newTagInt,
      min: v,
      default: v > newTagInt.default ? v : newTagInt.default,
    });
  };
  const handleNewTagMax = (v: number) => {
    setNewTagInt({
      ...newTagInt,
      max: v,
      default: v < newTagInt.default ? v : newTagInt.default,
    });
  };
  const handleNewTagDefaultStr = (v: Array<string>) => {
    let newV = v.length > 1 ? v[v.length - 1] : v[0];
    if (newV === undefined) {
      setNewTagStr({ ...newTagStr, default: newV });
      return;
    }
    newV = newV.includes("#") ? newV.split("#")[1] : newV;
    if (newTagStr.options.includes(newV)) {
      setNewTagStr({ ...newTagStr, default: newV });
    }
  };
  const handleNewTagOptions = (options: Array<string>) => {
    setNewTagStr({ ...newTagStr, options: options });
  };

  const handleNewTagDefaultMul = (v: string) => {
    setNewTagMul({ ...newTagMul, default: v });
  };

  const handleNewTagMulL1Options = (options: Array<string>) => {
    const intersection = Object.values(newTagMul.options).filter(({ name }) =>
      options.includes(name)
    );
    const intersection_name = intersection.map((item) => item.name);
    const _options = options.filter(
      (item) => !intersection_name.includes(item)
    );
    _options.forEach((option) => intersection.push({ name: option }));

    intersection.sort((a, b) => compareOptions(a.name, b.name));

    setNewTagMul((prev) => {
      return {
        ...newTagMul,
        options: Object.fromEntries(
          intersection.map((option) => [option.name, option])
        ),
      };
    });
  };

  const handleNewTagMulL2Options =
    (name: string) => (options: Array<string>) => {
      setNewTagMul({
        ...newTagMul,
        options: {
          ...newTagMul.options,
          [name]: {
            name: name,
            options: options.sort(compareOptions),
          },
        },
      });
    };

  const handleNewTagName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value }: { value: string } = e.target;
    setNewTagName(value.trim());
  };

  const handleCreateNewTag = () => {
    if (newTag.name.length < 1) {
      message.error("Name cannot be empty.");
      return;
    }

    if (newTag.type === "multi-level") {
      if (Object.keys(newTag.options).length < 1) {
        message.error("There must be at least one option.");
        return;
      }
      if (!newTag.default) {
        message.error("Default cannot be empty.");
        return;
      }
    }

    if (newTag.type === "string") {
      if (newTag.options.length < 1) {
        message.error("There must be at least one option.");
        return;
      }
      if (newTag.default.length < 1) {
        message.error("Default cannot be empty.");
        return;
      }
    }

    if (examName && subjectName) {
      const _subjectName = subjectName;
      let newExams: Exams = { ...exams };
      let newExam = newExams[examName];
      let newSubject = newExam.subjects[_subjectName];

      // tag has exited in this subject
      if (newTag.name in newSubject.tags) return;

      newSubject.tags[newTagName] = newTag;
      newExam.subjects[_subjectName] = newSubject;
      newExams[examName] = newExam;

      updateExams(newExams);
    }
  };
  const [cnt, setCnt] = useState<number>(0);
  const handleCancelNewTag = () => {
    setCnt(cnt + 1);
  };
  useEffect(() => {
    setNewTagName("");
    setNewTagInt(newTagIntInit);
    setNewTagStr(newTagStrInit);
  }, [cnt]);
  return (
    <>
      <Typography.Title level={4}>Create New Tag</Typography.Title>
      <Form.Item label={"Type"}>
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          options={["integer", "string", "multi-level"]}
          onChange={(e) => setNewTagType(e.target.value)}
          value={newTagType}
          size={"small"}
        />
      </Form.Item>
      {
        {
          integer: (
            <Form
              size={"small"}
              layout="vertical"
              labelCol={{ span: 3 }}
              labelAlign={"left"}
            >
              <Form.Item label="Name">
                <Input value={newTagName} onChange={handleNewTagName} />
              </Form.Item>
              <Form.Item label={`Default value`}>
                <InputNumber
                  min={newTagInt.min}
                  max={newTagInt.max}
                  defaultValue={newTagInt.default}
                  onChange={handleNewTagDefaultInt}
                />
              </Form.Item>
              <Form.Item label={`Max value`}>
                <InputNumber
                  min={newTagInt.min}
                  defaultValue={newTagInt.max}
                  onChange={handleNewTagMax}
                />
              </Form.Item>
              <Form.Item label={`Min value`}>
                <InputNumber
                  max={newTagInt.max}
                  defaultValue={newTagInt.min}
                  onChange={handleNewTagMin}
                />
              </Form.Item>
            </Form>
          ),

          string: (
            <Form
              size={"small"}
              layout="vertical"
              labelCol={{ span: 3 }}
              labelAlign={"left"}
            >
              <Form.Item label="Name">
                <Input value={newTagName} onChange={handleNewTagName} />
              </Form.Item>
              <Form.Item label={`Default value`}>
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  onChange={handleNewTagDefaultStr}
                  value={newTagStr.default ? [newTagStr.default] : []}
                >
                  {newTagStr.options.map((option) => (
                    <Select.Option key={`newOption#${option}`}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={`Options`}>
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  onChange={handleNewTagOptions}
                  value={newTagStr.options}
                  maxTagCount={10}
                />
              </Form.Item>
            </Form>
          ),
          "multi-level": (
            <Form
              size={"small"}
              layout="vertical"
              labelCol={{ span: 3 }}
              labelAlign={"left"}
            >
              <Form.Item label="Name">
                <Input value={newTagName} onChange={handleNewTagName} />
              </Form.Item>
              <Form.Item label={`Default value`}>
                <TreeSelect
                  allowClear
                  multiple={false}
                  style={{ width: "100%" }}
                  treeData={Object.values(newTagMul.options).map(
                    ({ name, options }) => {
                      return {
                        title: name,
                        value: name,
                        children: options?.map((option) => {
                          return {
                            title: option,
                            value: `${name}-${option}`,
                          };
                        }),
                      };
                    }
                  )}
                  onChange={handleNewTagDefaultMul}
                  value={newTagMul.default}
                />
              </Form.Item>
              <Form.Item label={`Level 1 Options`}>
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  onChange={handleNewTagMulL1Options}
                  value={Object.keys(newTagMul.options)}
                  maxTagCount={10}
                />
              </Form.Item>

              <Typography.Text
                strong
                style={{
                  display:
                    Object.keys(newTagMul.options).length > 0
                      ? "block"
                      : "none",
                }}
              >
                Level 2 Options
              </Typography.Text>

              {Object.entries(newTagMul.options).map(
                ([, { name, options }]) => (
                  <Form.Item key={name} label={`${name}`}>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      onChange={handleNewTagMulL2Options(name)}
                      value={options ? options : []}
                      maxTagCount={10}
                    />
                  </Form.Item>
                )
              )}
            </Form>
          ),
        }[newTagType]
      }
      <Space>
        <Button type="primary" onClick={handleCreateNewTag}>
          Create
        </Button>
        <Button onClick={handleCancelNewTag}>Cancel</Button>
      </Space>
    </>
  );
};

export { TagInt, TagMul, TagNew, TagStr };
