import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Button, Layout, List, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { Page } from "../../components/template";
import { Question } from "../../components/question";
import { Selector } from "../../components/selector";
import { db } from "../../utils/utils";
import { QUESTION_DB, TAG_DB } from "../../utils/env";

import "./index.scss";

const PageSize = 5;

const { Content } = Layout;

const getQuestions = ({
  exam,
  subject,
  properties,
  orderBy = undefined,
  page = 1,
}: Query) => {
  const dbQuery: Record<string, any> = {};

  Object.entries(properties).forEach(([n, v]) => {
    const [type, name] = n.split("#");
    switch (type) {
      case "string": {
        dbQuery[`properties.${name}`] = db.RegExp({
          regexp: `^${v}(@.*)?$`,
          options: "",
        });
        break;
      }
      case "number": {
        dbQuery[`properties.${name}`] = db.command.gte(v);
        break;
      }
    }
  });

  const query = { ...dbQuery, exam: exam, subject: subject };
  // console.log("from getQuestions: ", query, page);
  return orderBy
    ? db
        .collection(QUESTION_DB)
        .where(db.command.and(query))
        .orderBy(orderBy.field, orderBy.orderType)
        .skip((page - 1) * PageSize)
        .limit(PageSize)
        .get()
    : db
        .collection(QUESTION_DB)
        .where(db.command.and(query))
        .skip((page - 1) * PageSize)
        .limit(PageSize)
        .get();
};

const getQuestionsCnt = ({ exam, subject, properties }: Query) => {
  const dbQuery: Record<string, any> = {};

  Object.entries(properties).forEach(([n, v]) => {
    const [type, name] = n.split("#");
    switch (type) {
      case "string": {
        dbQuery[`properties.${name}`] = db.RegExp({
          regexp: `^${v}(@.*)?$`,
          options: "",
        });
        break;
      }
      case "number": {
        dbQuery[`properties.${name}`] = db.command.gte(v);
        break;
      }
    }
  });
  const query = { ...dbQuery, exam: exam, subject: subject };
  return db.collection(QUESTION_DB).where(db.command.and(query)).count();
};

const OrderByButton: FC<{
  field: string;
  orderBy: OrderBy | undefined;
  setOrderBy: Dispatch<SetStateAction<OrderBy | undefined>>;
}> = ({ field, orderBy, setOrderBy }) => {
  const myField = `properties.${field}`;

  const handleClick = () => {
    const nextOrderType = orderBy?.orderType === "asc" ? "desc" : "asc";
    const nextOrderBy = { field: myField, orderType: nextOrderType };
    setOrderBy(nextOrderBy);
  };

  return (
    <Button style={{ width: "100px" }} onClick={handleClick}>
      <Space>
        {field[0].toUpperCase() + field.slice(1)}{" "}
        {orderBy?.field === `properties.${field}` ? (
          orderBy?.orderType === "asc" ? (
            <UpOutlined />
          ) : (
            <DownOutlined />
          )
        ) : (
          <></>
        )}
      </Space>
    </Button>
  );
};

const Questions: FC = () => {
  const [questions, setQuestions] = useState<Array<Question>>([]);
  const [exams, setExams] = useState<Exams>({});
  const [page, setPage] = useState<number>(1);
  const [exam, setExam] = useState<string | undefined>();
  const [subject, setSubject] = useState<string | undefined>();
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>();
  const [properties, setProperties] = useState<Record<string, any>>({});
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const query: Query = {
    exam: exam,
    subject: subject,
    properties: properties,
    orderBy: orderBy,
    page: page,
  };

  const handleQuery = (q: Query) => {
    // assign exam, subject and properties from
    if (q.exam !== "") {
      const exam = q.exam;
      setExam(exam);
    }
    if (q.subject !== "") {
      const subject = q.subject;
      setSubject(subject);
    }
    if (Object.entries(q.properties).length > 0) {
      const properties = Object.fromEntries(
        Object.entries(q.properties).map(([n, v]) => [n, v])
      );
      setProperties(properties);
    }

    setPage(1);
  };

  useEffect(() => {
    if (exam && subject) {
      const fetchTotalCount = getQuestionsCnt(query);
      const fetchQuestions = getQuestions(query);

      setLoading(true);

      Promise.all([fetchTotalCount, fetchQuestions]).then(([res1, res2]) => {
        setCount(res1.total);
        setQuestions(res2.data);
        setLoading(false);
      });
    }
  }, [properties]);

  useEffect(() => {
    const fetchQuestionsCount = getQuestionsCnt(query);
    const fetchQuestions = getQuestions(query);
    const fetchTags = db.collection(TAG_DB).get();

    setLoading(true);
    Promise.all([fetchQuestionsCount, fetchQuestions, fetchTags]).then(
      ([resQuestionsCount, resQuestions, resTags]) => {
        setCount(resQuestionsCount.total);
        setQuestions(resQuestions.data);
        const { _id, _openid, ...exams } = resTags.data[0];
        setExams(exams);
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    getQuestions({
      exam: exam,
      subject: subject,
      properties: properties,
      orderBy: orderBy,
      page: page,
    }).then((res) => {
      console.log("questions", res.data);
      // console.log(res.data);
      setQuestions(res.data);
      setLoading(false);
    });
  }, [page]);

  return (
    <Page name={"Questions"}>
      <Layout className="site-layout-background">
        <Content
          className="site-page-background"
          style={{
            padding: "24px 0px",
            margin: 24,
            minHeight: 280,
            overflowY: "scroll",
          }}
        >
          <Space
            direction="vertical"
            style={{ display: "flex", width: "21cm", margin: "auto" }}
          >
            <Selector exams={exams} setQuery={handleQuery} />
            <Space>
              <OrderByButton
                field="year"
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <OrderByButton
                field="paper"
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
              <OrderByButton
                field="mark"
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
            </Space>
            <a href={"questions/new"} target="_blank">
              <Button type="primary" block>
                Add New Question
              </Button>
            </a>
            <List
              itemLayout="vertical"
              size="large"
              split={false}
              loading={loading}
              pagination={{
                onChange: (page) => setPage(page),
                current: page,
                pageSize: PageSize,
                pageSizeOptions: [PageSize],
                total: count,
              }}
              dataSource={questions}
              renderItem={(question) => (
                <List.Item
                  className="question-card"
                  style={{ flexDirection: "column", padding: "15px 15px" }}
                >
                  <a href={"questions/" + question._id} target="_blank">
                    <Question data={question} />
                  </a>
                </List.Item>
              )}
            />
          </Space>
        </Content>
      </Layout>
    </Page>
  );
};

export { Questions };
