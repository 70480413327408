import { FC } from "react";
import { Button, message, Popconfirm, Typography } from "antd";

const { Text } = Typography;

const Delete: FC<{ handleDelete: () => void }> = ({ handleDelete }) => {
  const confirm = () => {
    message.success("Click on Yes");
    handleDelete();
  };

  return (
    <Popconfirm title="Are you sure to delete this?" onConfirm={confirm} okText="Yes" cancelText="No">
      <Text style={{ color: "red" }}>Delete</Text>
    </Popconfirm>
  );
};

const DeleteButton: FC<{ handleDelete: () => void }> = ({ handleDelete }) => {
  const confirm = () => {
    message.success("Click on Yes");
    handleDelete();
  };

  return (
    <Popconfirm title="Are you sure to delete this?" onConfirm={confirm} okText="Yes" cancelText="No">
      <Button danger block>
        Delete
      </Button>
    </Popconfirm>
  );
};

export { Delete, DeleteButton };
