import { FC, ReactNode, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";

import type { MenuProps } from "antd";

import { auth } from "../../utils/utils";

const { Header, Footer } = Layout;

const navItems: MenuProps["items"] = ["Questions", "Tags"].map((key) => ({
  key,
  label: key,
}));

const Page: FC<{ name: string; children: ReactNode }> = ({ name, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    auth.getLoginState().then(async (loginState) => {
      if (loginState) {
        // 登录态有效
        console.log("Logged in, Welcome!");
      } else {
        // 没有登录态，或者登录态已经失效
        console.log("Have not logged in yet");
        navigate("/");
      }
    });
  }, []);

  return (
    <Layout style={{ height: "100vh" }}>
      <Header className="header">
        <div className="logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[name]}
          items={navItems}
          onClick={(e) => navigate(`/${e.key.toLowerCase()}`)}
        />
      </Header>
      {children}
      <Footer style={{ textAlign: "center" }}>MyQuestionBank @2022</Footer>
    </Layout>
  );
};

export { Page };
