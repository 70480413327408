import cloudbase from "@cloudbase/js-sdk";
import { ENV_ID } from "./env";

const app = cloudbase.init({ env: ENV_ID });

const auth = app.auth({ persistence: "local" });
const db = app.database();

const signUp = (email: string, password: string) => {
  auth.signUpWithEmailAndPassword(email, password).then((res) => {
    console.log(res);
  });
};

const getLoginState = (email: string, password: string) => {
  return auth
    .getLoginState()
    .then((loginState) => {
      if (loginState) {
        // 登录态有效
        return loginState;
      }
      // 没有登录态，或者登录态已经失效
      return auth
        .signInWithEmailAndPassword(email, password)
        .then((loginState) => loginState);
    })
    .then((loginState) => loginState);
};

const uploadImage = async (file: File) => {
  const res = await app.uploadFile({
    // 云存储的路径
    cloudPath: `test/${file.name}-${Date.now()}`,
    // 需要上传的文件，File 类型
    // @ts-ignore
    filePath: file,
  });
  // 返回文件 ID
  console.log(res.fileID);
  return {
    success: 1,
    file: {
      url:
        "https://" +
        res.fileID
          .replace(`cloud://${process.env.REACT_APP_ENV_ID}.`, "")
          .replace("/", ".tcb.qcloud.la/"),
      // ... and any additional fields you want to store, such as width, height, color, extension, etc
      fileID: res.fileID,
    },
  };
};

const deleteImage = (fileID: string) => {
  app.deleteFile({ fileList: [fileID] }).then((res) => {
    res.fileList?.forEach((el) => {
      if (el.code === "SUCCESS") {
        //删除成功
        console.log(`${fileID} is deleted`);
      } else {
        console.log(el);
      }
    });
  });
};

export { auth, db, deleteImage, getLoginState, signUp, uploadImage };
