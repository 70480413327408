import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Layout, Typography } from "antd";
import { Col, Row } from "antd";

import { auth } from "../../utils/utils";

const { Footer } = Layout;
const { Title } = Typography;

const Login: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    auth.getLoginState().then(async (loginState) => {
      if (loginState) {
        // 登录态有效
        console.log("Logged in, Welcome!");
        navigate("/questions");
      }
    });
  }, []);

  const onFinish = (values: any) => {
    console.log("Success: form values are all valid");
    const { email, password } = values;
    auth
      .signInWithEmailAndPassword(email, password)
      .then((loginState) => {
        console.log("login success");
        navigate("/questions");
      })
      .catch((err) => console.error(err));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    localStorage.removeItem("query");
    localStorage.removeItem("page");
  }, []);

  return (
    <Layout>
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col style={{ width: "fit-content" }}>
          <Title style={{ textAlign: "center" }}>MyQuestionBank</Title>
          <Row justify="center" align="middle" style={{ margin: 100 }}>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input autoComplete="on" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Row>
          <Footer style={{ textAlign: "center" }}>MyQuestionBank @2022</Footer>
        </Col>
      </Row>
    </Layout>
  );
};

export { Login };
