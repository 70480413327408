import { FC, Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Cascader, Col, Form, InputNumber, Row, Select, TreeSelect } from "antd";

import type { SingleValueType } from "rc-cascader/lib/Cascader";

import { db } from "../../utils/utils";
import { toUpperCase } from "../../utils/toUpperCase";
import { TAG_DB } from "../../utils/env";
import { sort } from "../../utils/sort";

import "./index.scss";

const { Option } = Select;

interface Option {
  value: string;
  label: string;
  children?: Option[];
  isLeaf?: boolean;
  loading?: boolean;
}

const SubjectSelector: FC<{
  subject: string | undefined;
  setSubject: Dispatch<SetStateAction<string | undefined>>;
  exam: string | undefined;
  setExam: Dispatch<SetStateAction<string | undefined>>;
}> = ({ subject, setSubject, exam, setExam }) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    db.collection(TAG_DB)
      .get()
      .then((res) => {
        const { _id, _openid, ...exams } = res.data[0];
        const options = Object.entries(exams as Exams).map((entry) => {
          const [, exam] = entry;
          return {
            label: exam.name,
            value: exam.name,
            children: Object.entries(exam.subjects).map((entry) => {
              const [, subject] = entry;
              return {
                label: subject.name,
                value: subject.name,
              };
            }),
          };
        });
        setOptions(options);
      });
  }, []);

  const onChange = (value: SingleValueType) => {
    if (value) {
      const [e, s] = value;
      const exam = String(e);
      const subject = String(s);

      setExam(exam);
      setSubject(subject);

      setSubject(subject);
      setExam(exam);
    }
  };

  return (
    <Cascader
      placeholder="select a subject first"
      options={options}
      value={[exam ? exam : "", subject ? subject : ""]}
      onChange={onChange}
      changeOnSelect={false}
    />
  );
};

const Selector: FC<{
  exams: Exams;
  setQuery: (q: Query) => void;
}> = ({ exams, setQuery }) => {
  const [form] = Form.useForm();
  const [exam, setExam] = useState<string | undefined>();
  const [subject, setSubject] = useState<string | undefined>();
  const [tags, setTags] = useState<{ [key: string]: Tag }>({});

  const onFinish = (values: { [key: string]: number | string }) => {
    console.log("Received values of form: ", values);
    const entries = Object.entries(values)
      .filter(([n, _]) => n !== "subject")
      .filter(([, v]) => v)
      .map(([n, v]) => {
        switch (typeof v) {
          case "string": {
            return [`string#${n}`, v];
          }
          case "number": {
            return [`number#${n}`, v];
          }
        }
      });
    // console.log(entries);
    const newQuery: Query = {
      exam: exam,
      subject: subject,
      properties: entries ? Object.fromEntries(entries) : {},
    };
    setQuery(newQuery);
  };

  useEffect(() => {
    const tags =
      exam && exams[exam] && subject && exams[exam].subjects[subject] ? exams[exam].subjects[subject].tags : {};
    setTags(tags);
    form.resetFields();
    form.setFieldsValue({ subject, exam });
  }, [exam, subject, exams]);

  const getFields = () => {
    const children = [];
    children.push(
      <Col span={8} key="subject">
        <Form.Item
          name={`subject`}
          label={`Subject`}
          rules={[
            {
              required: true,
              message: "Select a subject to start",
            },
          ]}
        >
          <SubjectSelector
            subject={subject}
            setSubject={(s) => {
              setSubject(s);
              form.setFieldsValue({ subject: s });
            }}
            exam={exam}
            setExam={setExam}
          />
        </Form.Item>
      </Col>
    );

    Object.entries(tags).forEach(([, tag]) => {
      children.push(
        <Col span={8} key={tag.name}>
          <Form.Item
            name={tag.name}
            label={toUpperCase(tag.name)}
            rules={[
              {
                required: false,
                message: "Input something!",
              },
            ]}
          >
            {
              {
                integer: (
                  <InputNumber
                    placeholder={`${(tag as TagInt).min} - ${(tag as TagInt).max}`}
                    style={{ width: "100%" }}
                    min={(tag as TagInt).min}
                    max={(tag as TagInt).max}
                    value={tag.default}
                    onChange={(v) => form.setFieldsValue({ [tag.name]: v })}
                  />
                ),
                string:
                  tag.type === "string" ? (
                    <Select
                      allowClear
                      placeholder="please select an option"
                      value={tag.default}
                      onChange={(v) => form.setFieldsValue({ [tag.name]: v })}
                    >
                      {tag.options.sort(sort).map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <></>
                  ),
                "multi-level":
                  tag.type === "multi-level" ? (
                    <TreeSelect
                      allowClear
                      placeholder="please select an option"
                      treeData={Object.values(tag.options)
                        ?.sort((a, b) => sort(a.name, b.name))
                        .map(({ name, options }) => {
                          return {
                            title: name,
                            value: name,
                            children: options?.sort(sort).map((option) => {
                              return {
                                title: option,
                                value: `${name}@${option}`,
                              };
                            }),
                          };
                        })}
                    />
                  ) : (
                    <></>
                  ),
              }[tag.type]
            }
          </Form.Item>
        </Col>
      );
    });
    return children;
  };
  return (
    <Form
      form={form}
      style={{ backgroundColor: "#eee" }}
      name="question-search"
      className="question-search"
      onFinish={onFinish}
    >
      <Row gutter={24}>{getFields()}</Row>
      <Row>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export { Selector };
