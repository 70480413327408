import { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QuestionEditor } from "./pages/questionEditor";
import { Questions } from "./pages/questions";
import { TagEditor } from "./pages/tagEditor";
import { Login } from "./pages/login";

import "./App.scss";

const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/questions" element={<Questions />} />
        <Route path="/questions/:questionId" element={<QuestionEditor />} />
        <Route path="/tags" element={<TagEditor />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
