const toUpperCase = (word: string) => {
  if (!word) return;
  const list = word.split(" ");
  return list
    .filter((w) => w)
    .map((w) => w[0].toUpperCase() + w.substring(1))
    .join(" ");
};

export { toUpperCase };
