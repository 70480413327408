class BlankLine {
  static get toolbox() {
    return {
      title: "BlankLine",
      icon: String.raw`<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 12h14M5 16h6"/></svg>`,
    };
  }
  constructor(options: BlockToolConstructorOptions) {
    const { data } = options;
    this.data = data;
    this.show = true;
    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("blank-lines");
  }
  render() {
    const input = document.createElement("input");
    const blankLines = document.createElement("div");
    blankLines.id = "blank-lines";

    input.setAttribute("type", "number");
    input.setAttribute("min", 1);
    input.setAttribute("max", 20);
    input.value = this.data && this.data.number ? this.data.number : 1;

    input.addEventListener("change", (event) => {
      this._renderBlankLines(event.target.value);
    });

    blankLines.addEventListener("click", (event) => {
      if (this.show) {
        this._hideInput();
      } else {
        this._showInput();
      }
      this.show = !this.show;
    });

    this.wrapper.appendChild(input);
    this.wrapper.appendChild(blankLines);
    this._renderBlankLines(input.value);

    return this.wrapper;
  }
  save(blockContent) {
    const input = blockContent.querySelector("input");
    const number = Number(input?.value);
    return {
      number: number,
    };
  }
  validate(savedData) {
    if (!Number.isInteger(savedData.number)) {
      return false;
    }
    if (savedData.number < 0) {
      return false;
    }

    return true;
  }
  _renderBlankLines(number) {
    const blankLines = this.wrapper.querySelector("#blank-lines");
    blankLines.innerHTML = "";
    for (let i = 0; i < number; i++) {
      const line = document.createElement("hr");
      line.classList.add("dashed");
      blankLines.appendChild(line);
    }
  }

  _hideInput() {
    const input = this.wrapper.querySelector("input");
    input.style.display = "none";
  }

  _showInput() {
    const input = this.wrapper.querySelector("input");
    input.style.display = "";
  }
}

export { BlankLine };
