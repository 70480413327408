import React, { useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import ImageTool from "@editorjs/image";
import { deleteImage, uploadImage } from "../../utils/utils";
import { BlankLine } from "./blankLine";
import { Answer, Math } from "./math";
import { ImageAnswer } from "../imageAnswer/index.js";

import { OutputData } from "@editorjs/editorjs";

import "./index.css";

const EDITOR_HOLDER_ID = "editorjs";

enum LogLevels {
  VERBOSE = "VERBOSE",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}

const Editor = React.memo(
  (props: { data: EditorData; setData: (arg: EditorData) => void }) => {
    const { data, setData } = props;
    const { _id, _openid, ...content } = data;
    const editorInstance = useRef<EditorJS>();

    // This will run only once
    useEffect(() => {
      if (!editorInstance.current) {
        initEditor(content);
      }
      return () => {
        editorInstance.current?.destroy();
        editorInstance.current = undefined;
      };
    }, []);

    const initEditor = (content: OutputData) => {
      const editor = new EditorJS({
        holder: EDITOR_HOLDER_ID,
        logLevel: LogLevels.ERROR,
        // @ts-ignore
        data: content,
        // autofocus: true,
        defaultBlock: "paragraph",
        tools: {
          // header: Header,
          paragraph: {
            class: Math,
          },
          image: {
            class: ImageTool,
            config: {
              uploader: {
                uploadByFile(file: any) {
                  console.log(file);
                  return uploadImage(file);
                },
              },
              actions: [],
            },
          },
          textAnswer: {
            class: Answer,
          },
          imageAnswer: {
            class: ImageAnswer,
            config: {
              uploader: {
                uploadByFile(file: any) {
                  console.log(file);
                  return uploadImage(file);
                },
              },
              actions: [],
            },
          },
          blankLine: { class: BlankLine, config: {} },
        },
        onReady: () => {
          editorInstance.current = editor;
        },
        onChange: async () => {
          const content = await editorInstance.current?.save();
          // Put your logic here to save this data to your DB
          setData({ _id, _openid, ...content! });
        },
      });
    };

    return <div id={EDITOR_HOLDER_ID} />;
  }
);

export { Editor };
