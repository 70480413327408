import { decodeSpecialChar } from "../../utils/decode.ts";

class Math {
  constructor(options: BlockToolConstructorOptions) {
    const { data, api } = options;
    this.api = api;
    this.content = data.content;
    this.show = true;
    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("math");
  }
  static get toolbox() {
    return {
      title: "Text",
      icon: "<svg width='32px' height='32px' viewBox='0 0 32 32' id='icon' xmlns='http://www.w3.org/2000/svg'><polygon points='26 18 24 18 22 21.897 20 18 18 18 20.905 23 18 28 20 28 22 24.201 24 28 26 28 23.098 23 26 18'/><path d='M19,6V4H13.9133a1.9906,1.9906,0,0,0-1.9919,1.8188L11.2686,13H7v2h4.0867l-1,11H5v2h5.0867a1.9906,1.9906,0,0,0,1.9919-1.8188L13.0952,15H18V13H13.2769l.6364-7Z'/></svg>",
    };
  }
  render() {
    const input = document.createElement("textarea");
    const math = document.createElement("p");
    math.id = "math";

    input.setAttribute("rows", 5);
    input.classList.add("math-input");
    // input.classList.add("cdx-block");
    input.value = this.content ? decodeSpecialChar(this.content) : "";

    input.addEventListener("change", (event) => {
      this._renderMath(event.target.value);
    });

    input.addEventListener("keydown", (event) => {
      const content = input.value;
      if (content.trim().length === 0 && event.key === "Backspace") {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();
        this.api.blocks.delete(blockIndex + 1);
      }
    });

    math.addEventListener("mouseup", (event) => {
      if (this.show) {
        this._hideTextArea();
      } else {
        this._showTextArea();
      }
      this.show = !this.show;
    });

    this.wrapper.appendChild(math);
    this.wrapper.appendChild(input);
    this._renderMath(input.value);

    return this.wrapper;
  }
  save(blockContent) {
    const input = blockContent.querySelector("textarea");
    const content = input.value;
    return {
      content: content,
    };
  }
  validate(savedData) {
    if (typeof savedData.content === "string" && savedData.content.trim().length === 0) {
      return false;
    }
    if (savedData.data < 0) {
      return false;
    }

    return true;
  }
  _renderMath(content) {
    const math = this.wrapper.querySelector("#math");
    math.textContent = content;
  }
  _hideTextArea() {
    const input = this.wrapper.querySelector("textarea");
    input.style.display = "none";
  }
  _showTextArea() {
    const input = this.wrapper.querySelector("textarea");
    input.style.display = "";
  }
}

class Answer extends Math {
  constructor(options: BlockToolConstructorOptions) {
    super(options);
    const { data, api } = options;
    this.api = api;
    this.content = data.content;
    this.show = true;
    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("math");
    this.wrapper.classList.add("answer");
  }
  static get toolbox() {
    return {
      title: "Text Answer",
      icon: "<svg width='32px' height='32px' viewBox='0 0 32 32' id='icon' xmlns='http://www.w3.org/2000/svg'><polygon points='26 18 24 18 22 21.897 20 18 18 18 20.905 23 18 28 20 28 22 24.201 24 28 26 28 23.098 23 26 18'/><path d='M19,6V4H13.9133a1.9906,1.9906,0,0,0-1.9919,1.8188L11.2686,13H7v2h4.0867l-1,11H5v2h5.0867a1.9906,1.9906,0,0,0,1.9919-1.8188L13.0952,15H18V13H13.2769l.6364-7Z'/></svg>",
    };
  }
}

export { Math, Answer };
